import * as React from 'react';

import './KiteContextualMenu.scss';
import KiteFontIcon from '../../icons/KiteFontIcon/KiteFontIcon';

export interface IMenuItem {
  label: string;
  render?: React.ReactNode;
}

export interface IMenuProps {
  items?: IMenuItem[];
  onClick: (item: IMenuItem, event: any) => any;
  menuAlignment?: 'left' | 'right' | '';
  buttonContent?: React.ReactNode | string;
}

export interface IMenuState {
  showMenu: boolean;
}

class KiteContextualMenu extends React.Component<IMenuProps, IMenuState> {
  static defaultProps = {
    items: [],
    onClick: null,
    menuAlignment: '',
    buttonContent: <KiteFontIcon name="dots-3-vert-f" />,
  };

  menuButton: HTMLButtonElement | null = null;

  itemsContainer: HTMLUListElement | null = null;

  firstItem: HTMLButtonElement | null = null;

  lastItem: HTMLButtonElement | null = null;

  state = {
    showMenu: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  // TODO: CHANGE TARGET TYPE
  handleClickOutside = (e: { target: any }) => {
    if (
      this.itemsContainer !== null &&
      !this.itemsContainer.contains(e.target)
    ) {
      this.setState({ showMenu: false });
      document.removeEventListener('click', this.handleClickOutside, false);
    }
  };

  openDropdown = () => {
    this.setState({ showMenu: true }, () => {
      if (this.firstItem !== null) this.firstItem.focus();
      document.addEventListener('click', this.handleClickOutside, false);
    });
  };

  closeDropdown = () => {
    this.setState({ showMenu: false }, () => {
      if (this.menuButton !== null) this.menuButton.focus();
      document.removeEventListener('click', this.handleClickOutside, false);
    });
  };

  handleMenuButtonClick = (e: any) => {
    e.stopPropagation();
    this.openDropdown();
  };

  handleMenuButtonKeydown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (e.keyCode) {
      case 32:
        e.preventDefault();
        this.openDropdown();
        break;
      default:
        break;
    }
  };

  handleDropdownItemClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    item: IMenuItem
  ) => {
    const { onClick } = this.props;
    e.stopPropagation();
    onClick(item, e);
    this.closeDropdown();
  };

  // TODO: CHANGE EVENT TYPE
  handleDropdownItemKeydown = (e: any, item) => {
    const { onClick } = this.props;

    switch (e.keyCode) {
      // escape key
      case 27:
        e.preventDefault();
        e.stopPropagation();
        this.closeDropdown();
        break;
      // space key
      case 32:
        e.preventDefault();
        this.closeDropdown();
        onClick(item, e);
        break;
      // enter key
      case 13:
        e.preventDefault();
        this.closeDropdown();
        onClick(item, e);
        break;
      // tab key
      case 9:
        this.closeDropdown();
        break;
      // down arrow
      case 40:
        e.preventDefault();
        if (e.target.parentNode.nextElementSibling) {
          e.target.parentNode.nextElementSibling.firstChild.focus();
        } else {
          if (this.firstItem !== null) this.firstItem.focus();
        }
        break;
      // up arrow
      case 38:
        e.preventDefault();
        if (e.target.parentNode.previousElementSibling) {
          e.target.parentNode.previousElementSibling.firstChild.focus();
        } else {
          if (this.lastItem !== null) this.lastItem.focus();
        }
        break;
      default:
        break;
    }
  };

  render() {
    const { showMenu } = this.state;
    const { items, menuAlignment, buttonContent } = this.props;

    let styles = {};
    styles =
      menuAlignment === 'left'
        ? { ...styles, justifyContent: 'flex-start' }
        : styles;

    let menuStyles = {};
    menuStyles =
      menuAlignment === 'left'
        ? { ...menuStyles, left: '0', transform: 'translateX(0)' }
        : menuStyles;

    const displayMenuItems = items
      ? items.map((item, i) => (
          <li className="kite-con-menu__menu-item" key={item.label}>
            <button
              className="kite-con-menu__menu-item-btn"
              onClick={e => this.handleDropdownItemClick(e, item)}
              onKeyDown={e => this.handleDropdownItemKeydown(e, item)}
              ref={el => {
                if (i === 0) {
                  this.firstItem = el;
                }
                if (items && i === items.length - 1) {
                  this.lastItem = el;
                }
              }}
            >
              {item.render || item.label}
            </button>
          </li>
        ))
      : [];

    return (
      <div className="kite-con-menu" style={styles}>
        <button
          className="kite-con-menu__button"
          onClick={this.handleMenuButtonClick}
          onKeyDown={this.handleMenuButtonKeydown}
          aria-expanded={showMenu}
          ref={el => {
            this.menuButton = el;
          }}
        >
          {buttonContent}
        </button>
        {showMenu && (
          <div>
            <ul
              className="kite-con-menu__menu"
              style={menuStyles}
              ref={el => {
                this.itemsContainer = el;
              }}
            >
              {displayMenuItems}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default KiteContextualMenu;
