import { useCallback, useContext } from 'react';
import { QuantumContext } from '../contexts/quantumContext';

type SelectOpType =
  | 'accessibilityToggle'
  | 'actionTileSelect'
  | 'autoCollapse'
  | 'autoDismiss'
  | 'autoPayToggle'
  | 'autoplayToggle'
  | 'buttonClick'
  | 'closedCaptionLanguage'
  | 'closedCaptionStyle'
  | 'closedCaptionTextSize'
  | 'closedCaptionToggle'
  | 'collapseClick'
  | 'curatedCatalogCastAndCrew'
  | 'curatedSearchCastAndCrew'
  | 'curatedSearchEvents'
  | 'curatedSearchNetworks'
  | 'curatedSearchSports'
  | 'curatedSearchTeam'
  | 'dataNoticeToggle'
  | 'dataReset'
  | 'doubleTap'
  | 'expandClick'
  | 'favoritesUpdated'
  | 'favoriteToggle'
  | 'filterApplied'
  | 'guideNarrationToggle'
  | 'infoBarActivation'
  | 'letterPicker'
  | 'modalLaunch'
  | 'navigationClick'
  | 'networkSelection'
  | 'paginationClick'
  | 'paperlessToggle'
  | 'parentalControlNetworkToggle'
  | 'parentalControlToggle'
  | 'picker'
  | 'pinch'
  | 'playButtonClicked'
  | 'ratingRestrictionsUpdated'
  | 'relatedArticleSelection'
  | 'remoteControlBack'
  | 'remoteControlClick'
  | 'sapToggle'
  | 'searchStarted'
  | 'secondaryAudioToggle'
  | 'singleTap'
  | 'sortApplied'
  | 'swipe'
  | 'toolTipLaunch'
  | 'unpinch'
  | 'volumeChange'
  | 'watchListAdd'
  | 'watchListRemove'
  | 'wideScreenToggle'
  | 'toggleFlip'
  | 'dropDown'
  | 'blockedNetworksUpdated';

interface ISelectActionOptions {
  currPageElemStdName?: string;
  msgCategory?: 'navigation' | 'search' | 'contentDiscovery' | 'authentication';
  msgTriggeredBy?: 'user' | 'application';
  opType?: SelectOpType;
  [key: string]: any;
}

interface IPageViewOptions {
  currPageName?: string;
  currPageAppSection?: string;
  currPageIsLazyLoad?: boolean;
  msgTriggeredBy?: 'user' | 'application';
  [key: string]: any;
}

type LoginOpType =
  | 'manualAuth'
  | 'autoAccess'
  | 'resumeAuth'
  | 'verifierAuth'
  | 'tokenExchange'
  | 'ssoAuth'
  | 'appAuthorize'
  | 'trustedAuth'
  | 'biometricAuth'
  | 'tvEverywhere';

interface ILoginStopOptions {
  accountNumber?: string;
  accountGUID?: string;
  [key: string]: any;
}

export const useQuantum = () => {
  const analytics = useContext(QuantumContext);

  const trackSelectAction = useCallback(
    (currPageElemStdName: string, options: ISelectActionOptions = {}) => {
      analytics?.track('selectAction', {
        currPageElemStdName,
        currPageElemUiName: currPageElemStdName,
        msgCategory: 'contentDiscovery',
        msgTriggeredBy: 'user',
        opType: 'buttonClick',
        ...options,
      });
    },
    [analytics]
  );

  const trackPageView = useCallback(
    (pageName: string, options: IPageViewOptions = {}) => {
      analytics?.trackPageView(pageName, {
        msgTriggeredBy: 'user',
        currPageIsLazyLoad: false,
        ...options,
      });
    },
    [analytics]
  );

  const trackLoginStart = useCallback(
    (opType: LoginOpType) => {
      analytics?.track('loginStart', {
        opType,
      });
    },
    [analytics]
  );

  const trackLoginStop = useCallback(
    (opSuccess: boolean, options: ILoginStopOptions = {}) => {
      analytics?.track('loginStart', {
        opSuccess,
        ...options,
      });
    },
    [analytics]
  );

  return {
    analytics,
    trackSelectAction,
    trackPageView,
    trackLoginStart,
    trackLoginStop,
  };
};
