// Packages
import React from 'react';
import { withRouter } from 'react-router-dom'

// Hooks
import { useLocalStorage } from '../../hooks/useLocalStorage';

// Components
import { ReactComponent as SpectrumNewsLogo } from '../../svgs/ssa_spectrum_news_logo.svg';
import { ReactComponent as SportsNetLogo } from '../../svgs/sportsNetLogo.svg';
// Utils
import getCopy from '../../utils/languages/getCopy';
import { getBaseURL, isSpecNews, getRequestorId, getValidRedirectURL } from '../../utils/utilityFunctions/utilityFunctions';

// Styles
import './SignIn.scss';

const SignIn = (props: any) => {

    // Hooks
    const [language, setLanguage] = useLocalStorage('language', 'english');

    // Handlers
    const handleLanguageToggle = () => {
        const displayLanguage = language === 'english' ? 'spanish' : 'english';
        setLanguage(displayLanguage);
      };

    const handleSignInWithSpectrum = () => {
      const regCode = props?.history?.location?.state?.regCode || "";
      const baseURL = props?.history?.location?.state?.baseURL || getBaseURL();
      const host = getValidRedirectURL();
      const url = `${baseURL}/api/v1/authenticate?mso_id=Spectrum&requestor_id=${getRequestorId()}&reg_code=${regCode}&no_flash=true&no_iframe=true&domain_name=${host}&redirect_url=https%3A%2F%2F${host}%2Fsuccess`;
      window.location.href = url;
    }
    
    const handleSignInWithAnotherProvider = () => {
        const regCode = props?.history?.location?.state?.regCode || "";
        const baseURL = props?.history?.location?.state?.baseURL || getBaseURL();
        const device = props?.history?.location?.state?.device;
        props.history.push({
          pathname: "/chooseprovider",
          state: {
            regCode,
            baseURL,
            device
           } 
        })
    }

  return (
    <div className="signin-screen">
      <div className="signin-screen__logo">
      {isSpecNews() ? (
          <SpectrumNewsLogo />
        ) : (
          <SportsNetLogo />
        )}
      </div>
      <h1 className="signin-screen__title">{getCopy(language).signInTitle}</h1>
      <p className="signin-screen__description">
        {isSpecNews() ? getCopy(language).signInDescriptionSpecNews : getCopy(language).signInDescriptionSportsNet}
      </p>
      <button
          className="signin-screen__signInWithSpectrumButton"
          onClick={handleSignInWithSpectrum}
      >
          {getCopy(language).signInWithSpectrumButton}
      </button>
      <button
        className="signin-screen__signInWithAnotherProviderButton"
        onClick={handleSignInWithAnotherProvider}
      >
        {getCopy(language).signInWithAnotherProviderButton}
      </button>
      <button
        className="signin-screen__language"
        onClick={handleLanguageToggle}
      >
        {language === 'english' ? `Español` : `English`}
      </button>
    </div>
  );
};

export default withRouter(SignIn);