const spanishCopy: { [key: string]: string } = {
  errorMessageEmpty: 'Ingresa el código de activación exactamente como aparece en tu televisor.',
  'NSALI-1010': 'El código que ingresaste no es válido. Vuelve a intentarlo más tarde.',
  'NSALI-5000':'Lo sentimos, algo no ha funcionado bien. Vuelve a intentarlo más tarde.',
  regCodeTitle: 'Ingresa el Código de Activación',
  regCodeDescriptionSpecNews: 'Ingresa el código de activación que aparece en la aplicación Spectrum News en tu televisor.',
  regCodeDescriptionSportsNet:'Ingresa el código de activación que aparece en la aplicación SportsNet en tu televisor.',
  regCodeSubmitButton: 'Verificar',
  successTitle: 'Gracias Por Confirmar Tu Cuenta',
  successDescription1SpecNews: 'Vuelve a tu televisor para ver los detalles de tu acceso a Spectrum News.',
  successDescription1SportsNet: "Vuelve a tu televisor para ver los detalles de tu acceso a SportsNet.",
  successDescription2: 'Puedes cerrar esta ventana.',
  signInTitle: 'Inicia Sesión Para Acceso Ilimitado',
  signInDescriptionSpecNews: 'Para seguir teniendo acceso ilimitado a Spectrum News, inicia sesión con tu cuenta de Spectrum u otro proveedor de televisión calificado.',
  signInDescriptionSportsNet: 'Para seguir teniendo acceso ilimitado a SportsNet, inicia sesión con tu cuenta de Spectrum u otro proveedor de televisión calificado.',
  signInWithSpectrumButton: 'Iniciar Sesión con Spectrum',
  signInWithAnotherProviderButton: 'Iniciar Sesión con Otro Proveedor',
  chooseProviderTitle: 'Elige Tu Proveedor',
  chooseProviderDescription: '¿No eres cliente de Spectrum? Selecciona tu proveedor de televisión calificado para iniciar sesión.'
};

export default spanishCopy;
