import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ChooseProvider, Home, SignIn } from '../../pages';
import Success from '../../pages/Success';

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route exact path="/signin">
      <SignIn />
    </Route>
    <Route exact path="/chooseprovider">
      <ChooseProvider />
    </Route>
    <Route exact path="/success">
      <Success />
    </Route>
  </Switch>
);

export default Routes;
