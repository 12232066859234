// Packages
import React, { useEffect } from 'react';

// Hooks
import { useQuantum } from '../../hooks/useQuantum';

// Components
import { Layout, RegCodeForm } from '../../components';

// Styles
import './Home.scss';

const Home = () => {
  // Quantum
  const { trackPageView } = useQuantum();

  useEffect(() => {
    let { search } = window.location;
    const qrCode = new URLSearchParams(search).get('code');
    const pageName = qrCode ? 'Home - QR Code' : 'Home - Manual';
    trackPageView(pageName);
  }, [trackPageView]);

  return (
    <Layout>
      <RegCodeForm />
    </Layout>
  );
};

export default Home;
