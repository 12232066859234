import axios from 'axios';

export const getVerbatimErrors = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: 'https://api.spectrum.net/tdcs/public/errors?apikey=l7xx27bd23984e404c4188a26dfd2227b70b&clientType=NEWS%20SECOND%20SCREEN%20AUTH',
    });
    return res.data;
  } catch (error) {
    console.log(`error`, error);
  }
};
