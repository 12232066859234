/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import classNames from 'classnames';

import './KiteTooltip.scss';
import KiteIcon from '../../icons/KiteIcon/KiteIcon';
import KiteButton from '../../buttons/KiteButton/KiteButton';

const whiteColor = '#fff';
const blueColor = '#0062b2';

export interface ITooltipProps {
  /** Children (sets content of the Tooltip) */
  children: React.ReactNode | string;
  /** The title used for the ara-label attribute. */
  ariaLabel?: string;
  /** This should be the same as the id of the associated input. */
  ariaControls: string;
  /** DEPRECATED, use aria-controls */
  ariaId?: string;
  /** If true, use a while-colored tooltip icon, rather than the default dark-blue. */
  isLight?: boolean;
  /** Additional classNames */
  className?: string;
}

export interface ITooltipState {
  tooltipOpen: boolean;
  tooltipPositionClasses: string;
  isHovering: boolean;
}

/**
 * An question-mark icon that when clicked will open a small floating-yet-connected info-bubble, containing any content that is passed in as children. The position of the tooltip relative to the icon is calculated on each hover event, and depends on the position of the tooltip in the window, as outlined in the guidelines. [Tooltip design guidelines](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/tooltips)
 */
class KiteTooltip extends React.Component<ITooltipProps, ITooltipState> {
  static defaultProps = {
    isLight: false,
    className: '',
    ariaLabel: '',
    ariaId: '',
    ariaControls: '',
  };

  state = {
    tooltipOpen: false,
    tooltipPositionClasses: '',
    isHovering: false,
  };

  tooltipButton: HTMLButtonElement | null = null;

  tooltipBubble: HTMLDivElement | null = null;

  linkElements: any = null;

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside = (e: {
    target: any;
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => {
    if (this.tooltipBubble && !this.tooltipBubble.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
      this.tooltipButton && this.tooltipButton.focus();
      this.setState({ tooltipOpen: false }, () => {
        document.removeEventListener('click', this.handleClickOutside, false);
      });
    }
  };

  // TODO: CHANGE EVENT TYPE
  handleToggleTooltip = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState((state: ITooltipState) => {
      if (!state.tooltipOpen) {
        this.setPositionClasses();
        document.addEventListener('click', this.handleClickOutside, false);
        this.tooltipBubble && this.tooltipBubble.focus();
        return { tooltipOpen: true };
      } else {
        document.removeEventListener('click', this.handleClickOutside, false);
        this.tooltipButton && this.tooltipButton.focus();
        return { tooltipOpen: false };
      }
    });
  };

  hideTooltip = () => {
    this.setState({ tooltipOpen: false }, () => {
      document.removeEventListener('click', this.handleClickOutside, false);
    });
  };

  handleTooltipKeydown = (e: {
    keyCode: any;
    preventDefault: () => void;
    target: any;
  }) => {
    if (
      e.target.classList.contains('kite-icon__button') ||
      e.target.classList.contains('kite-tooltip__close-button')
    ) {
      return;
    }
    const lastLink = this.linkElements[this.linkElements.length - 1];
    switch (e.keyCode) {
      case 32:
        e.preventDefault();
        this.handleToggleTooltip(e);
        break;
      case 27:
        this.hideTooltip();
        if (this.tooltipButton) {
          this.tooltipButton.focus();
        }
        break;
      case 9:
        if (e.target === lastLink || this.linkElements.length === 0) {
          this.hideTooltip();
        }
        break;
      default:
        break;
    }
  };

  setPositionClasses = () => {
    const elOffset = this.tooltipButton
      ? this.tooltipButton.getBoundingClientRect()
      : { left: 0, width: 0, top: 0, height: 0 };

    const elLeft = elOffset.left + elOffset.width / 2;

    let xClass;
    let yClass;

    if (typeof window !== 'undefined') {
      xClass = elLeft > window.innerWidth / 2 ? 'right' : 'left';
      yClass = 'bottom';
    }

    this.setState({ tooltipPositionClasses: `${xClass} ${yClass}` });
  };

  render() {
    const {
      children,
      isLight,
      ariaLabel,
      ariaControls,
      ariaId,
      className,
    } = this.props;

    const { tooltipOpen, tooltipPositionClasses, isHovering } = this.state;

    const fillColor = isLight ? whiteColor : blueColor;
    const outlineColor = isLight ? blueColor : whiteColor;

    return (
      <div className={`kite-tooltip ${className}`}>
        <button
          className="kite-tooltip__button"
          ref={el => {
            this.tooltipButton = el;
          }}
          onMouseEnter={() => this.setState({ isHovering: true })}
          onFocus={() => this.setState({ isHovering: true })}
          onMouseLeave={() => this.setState({ isHovering: false })}
          onBlur={() => this.setState({ isHovering: false })}
          onClick={this.handleToggleTooltip}
          onKeyDown={e => this.handleTooltipKeydown(e)}
          aria-label={ariaLabel}
          aria-expanded={tooltipOpen}
          aria-controls={ariaControls || ariaId}
          tabIndex={0}
          type="button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 26 26">
            <g fillRule="evenodd">
              <path
                fill={isHovering ? fillColor : outlineColor}
                stroke={isLight ? outlineColor : fillColor}
                strokeWidth="2"
                fillRule="nonzero"
                d="M12 0c1.688 0 3.242.305 4.664.914a12.113 12.113 0 0 1 3.82 2.602 12.113 12.113 0 0 1 2.602 3.82C23.696 8.758 24 10.312 24 12c0 1.672-.312 3.234-.938 4.688a11.843 11.843 0 0 1-2.578 3.796 11.48 11.48 0 0 1-1.734 1.465c-.625.43-1.312.801-2.063 1.113-.75.313-1.511.547-2.285.704-.773.156-1.574.234-2.402.234-1.672 0-3.234-.312-4.688-.938a11.843 11.843 0 0 1-3.796-2.578 11.843 11.843 0 0 1-2.579-3.797C.313 15.235 0 13.672 0 12c0-.828.078-1.629.234-2.402.157-.774.391-1.535.704-2.286.312-.75.683-1.437 1.113-2.062a11.48 11.48 0 0 1 1.465-1.734A11.843 11.843 0 0 1 7.312.937C8.767.313 10.329 0 12 0z"
              />

              <path
                fill={isHovering ? outlineColor : fillColor}
                d="M12 15.867c-.547 0-1.004.184-1.371.551-.367.367-.55.824-.55 1.371s.183 1.004.55 1.371c.367.367.824.55 1.371.55s1.004-.183 1.371-.55c.367-.367.55-.824.55-1.37 0-.548-.183-1.005-.55-1.372-.367-.367-.824-.55-1.371-.55z"
              />

              <path
                fill={isHovering ? outlineColor : fillColor}
                d="M12.023 4.852c-.656 0-1.253.109-1.793.328-.539.218-.996.515-1.37.89-.782.828-1.18 1.89-1.196 3.188h2.649c0-.625.148-1.164.445-1.617.297-.454.765-.68 1.406-.68.469 0 .84.133 1.113.398.274.266.41.672.41 1.22.016.952-.421 1.593-1.312 1.921-.922.344-1.437 1.281-1.547 2.813v1.265h2.414v-1.125c.11-1.094.688-1.805 1.735-2.133 1.015-.28 1.523-1.289 1.523-3.023 0-.328-.066-.695-.2-1.102-.132-.406-.378-.789-.738-1.148-.359-.36-.824-.649-1.394-.867-.57-.22-1.285-.328-2.145-.328z"
              />
            </g>
          </svg>
        </button>
        <div
          className={classNames({
            'kite-tooltip__bubble-bg': true,
            'kite-tooltip__bubble-bg--show': tooltipOpen,
            [tooltipPositionClasses]: tooltipPositionClasses,
          })}
        >
          <div
            className="kite-tooltip__bubble"
            role="presentation"
            ref={el => {
              if (el) {
                this.tooltipBubble = el;
                this.linkElements = el.querySelectorAll(
                  'a, button, Link, NavLink'
                );

                if (this.linkElements && this.linkElements.length) {
                  el.addEventListener('keydown', e =>
                    this.handleTooltipKeydown(e)
                  );
                }
              }
            }}
          >
            <div className="invisible-hover-area" />
            {typeof window !== 'undefined' && window.innerWidth < 600 && (
              <div className="kite-tooltip__close-icon-container">
                <KiteIcon
                  name="x"
                  size="24px"
                  onClick={this.handleToggleTooltip}
                  ariaLabel="close tooltip"
                />
              </div>
            )}
            {children}
            {typeof window !== 'undefined' && window.innerWidth < 600 && (
              <div className="kite-tooltip__close-button-container">
                <KiteButton
                  className="kite-tooltip__close-button"
                  size="full"
                  onClick={this.handleToggleTooltip}
                >
                  Close
                </KiteButton>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default KiteTooltip;
