const englishCopy: { [key: string]: string } = {
  errorMessageEmpty: 'Please enter the activation code exactly as it appears on your TV.',
  'NSALI-1010':'The access code you entered is invalid. Please try again.',
  'NSALI-5000': "We're sorry, something didn't work quite right. Please try again later.",
  regCodeTitle: 'Enter Activation Code',
  regCodeDescriptionSpecNews:'Please enter the activation code displayed by the Spectrum News App on your TV.',
  regCodeDescriptionSportsNet:'Please enter the activation code displayed by the SportsNet App on your TV.',
  regCodeSubmitButton: 'Verify',
  successTitle: "Thanks for Confirming Your Account",
  successDescription1SpecNews: "Please return to your TV for details about your access to Spectrum News.",
  successDescription1SportsNet: "Please return to your TV for details about your access to SportsNet.",
  successDescription2: 'You can close this browser window.',
  signInTitle: 'Sign In for Unlimited Access',
  signInDescriptionSpecNews: 'To continue getting free and unlimited access to Spectrum News, sign in with either your Spectrum Account or another qualifying TV provider.',
  signInDescriptionSportsNet: 'To continue getting free and unlimited access to SportsNet, sign in with either your Spectrum Account or another qualifying TV provider.',
  signInWithSpectrumButton: 'Sign In with Spectrum',
  signInWithAnotherProviderButton: 'Sign In with Another Provider',
  chooseProviderTitle: 'Choose Your Provider',
  chooseProviderDescription: 'Not a Spectrum customer? Select your qualifying TV provider below to sign in.'
};

export default englishCopy;
