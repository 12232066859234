import { useState } from 'react';

const getStorageValue = (key: string, defaultValue: any) => {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = saved ? JSON.parse(saved) : defaultValue;
  return initial;
};

export const useLocalStorage = (key: any, defaultValue: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  const setValue = (value: any) => {
    const valueToStore =
      value instanceof Function ? storedValue(storedValue) : value;
    setStoredValue(valueToStore);
    localStorage.setItem(key, JSON.stringify(valueToStore));
  };
  return [storedValue, setValue];
};
