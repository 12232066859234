import React from 'react';

import './Layout.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className = '' }: IProps) => {
  return (
    <>
      <main className={className}>{children}</main>
    </>
  );
};

export default Layout;
