/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import Helix from '@charter/helix/build/portals/index.min';
import packageJson from '../../../package.json';
import { appConfig } from '../../config/appConfig.ts';

const endpoints = {
  portals: {
    prod: 'https://collector.pi.spectrum.net/topics/sspp?bulk=true',
    staging: 'https://collector.pi-charter.net/topics/sspp?bulk=true',
  },
  internal_apps: {
    prod: 'https://collector.pi.spectrum.net/api/collector?bulk=true',
    staging: 'https://collector.pi-charter.net/api/collector?bulk=true',
  },
  video: {
    prod: 'https://collector.pi.spectrum.net/topics/internal_quantum?bulk=true',
    staging:
      'https://collector.pi-charter.net/topics/internal_quantum?bulk=true',
  },
};

const state = {
  analytics: null,
  defaultData: null,
  config: {},
};

const isBrowser = typeof window !== `undefined`;

export default class AnalyticsService {
  constructor(config, version) {
    try {
      if (config && version) {
        state.config = config;
        if (state.config) {
          state.config.appVersion = version;
        }
      } else {
        console.error(
          `Analytics Service | Error - No Config or Version provided.`
        );
        return;
      }
    } catch (e) {
      console.error(`Analytics Service | Error on Constructor : ${e}`);
      return;
    }

    this.init = this.init.bind(this);
    this.track = this.track.bind(this);
    this.trackPageView = this.trackPageView.bind(this);
    this.init();
  }

  init() {
    if (!isBrowser) {
      return null;
    }

    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      Helix.useDefaultStorage();

      let endpoint;
      if (
        window.location.host.split('.')[0] === appConfig.prodUrl.split('.')[0]
      ) {
        endpoint = endpoints[appConfig.appType].prod;
      } else {
        endpoint = endpoints[appConfig.appType].staging;
      }

      Helix.set('endpoint', endpoint);

      Helix.init(state.config)
        .then(() => {
          if (Helix.get('currentState') === 'blank') {
            Helix.trackStartSession(state.config.startSession);
          }
        })
        .catch((e) => console.error(e, 'INIT ERROR'));
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @doc method
   * @name analytics#getAnalytics
   * @methodOf analytics
   * @returns {AnalyticsService}
   * @description Gets/Inits the AnalyticsService
   */
  static getAnalytics(config, version) {
    if (!isBrowser) {
      return null;
    }
    if (!state.analytics) {
      state.analytics = new AnalyticsService(config, version);
    }
    return state.analytics;
  }

  /**
   * @doc method
   * @name analytics#getVisitId
   * @methodOf analytics
   * @returns {}
   * @description Gets the Current VisitId from the LocalStorage
   */
  static getVisitId() {
    if (!isBrowser) {
      return null;
    }
    let visitId = null;
    const libState = JSON.parse(window.localStorage.getItem('library-state'));
    // eslint-disable-next-line no-prototype-builtins
    if (libState && libState?.hasOwnProperty('model')) {
      visitId = libState.model.visit.visitId; // eslint-disable-line
    }
    return visitId;
  }

  /**
   * @doc method
   * @name analytics#track
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track
   */
  // eslint-disable-next-line consistent-return
  track(id, adHocData, options) {
    if (!isBrowser) {
      return null;
    }
    try {
      Helix.track(id, adHocData, options);
    } catch (err) {
      if (this.debugAppEvents) {
        console.error(err);
      }
    }
  }

  /**
   * @doc method
   * @name analytics#trackPageView
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track with a Complete PageView Cycle (Init-Completed). To be used when no Page load time calculation is required.
   */
  // eslint-disable-next-line consistent-return
  trackPageView(pageName, adHocData, pageUrl) {
    if (!isBrowser) {
      return null;
    }
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const msgEventType of ['pageViewInit', 'pageViewCompleted']) {
        Helix.track('pageView', {
          msgEventType,
          currPageUrl: pageUrl || window.location.href,
          currPageName: pageName,
          currPageAppSection: packageJson.name,
          ...adHocData,
        });
      }
    } catch (err) {
      if (this.debugAppEvents) {
        console.error(err);
      }
    }
  }
}
