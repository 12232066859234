// Packages
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import { appConfig } from './config/appConfig';

// Styles
import './App.css';
import { Routes } from './components';

const App = () => {
  // For dark mode, add "kite-theme-dark" class
  let endpoint;
  if (window.location.host.split('.')[0] === appConfig.prodUrl.split('.')[0]) {
    endpoint =
      'https://assets.adobedtm.com/c19d5759b404/dec8305e201a/launch-a4880a70fa3e.min.js';
  } else {
    endpoint =
      'https://assets.adobedtm.com/c19d5759b404/dec8305e201a/launch-9e6796c0c418-development.min.js';
  }

  const siteTitle = 'Get Instant Access to Spectrum News';
  const metaData = [
    {
      name: 'description',
      content:
        'Sign in with your Spectrum account to get instant access to Spectrum News on your TV.',
    },
  ];

  return (
    <div className="App">
      <Helmet title={siteTitle} meta={metaData}>
        <script src={endpoint} async></script>
      </Helmet>
      <Routes />
    </div>
  );
};

export default App;
