import React, { useState, useEffect, createContext, ReactNode } from 'react';

// Analytics
import AnalyticsService from '../utils/analytics/analytics';
import analyticsConfig from '../utils/analytics/analyticsConfig';
import packageJson from '../../package.json';

export const QuantumContext = createContext<AnalyticsService | undefined>(
  undefined
);

interface IProps {
  children: ReactNode;
}

export const QuantumContextProvider = ({ children }: IProps) => {
  const [analytics, setAnalytics] = useState<AnalyticsService | undefined>(
    undefined
  );

  useEffect(() => {
    const newAnalytics = new AnalyticsService(
      analyticsConfig,
      packageJson.version
    );

    setAnalytics(newAnalytics);
  }, []);

  return (
    <QuantumContext.Provider value={analytics}>
      {children}
    </QuantumContext.Provider>
  );
};
