import spanishCopy from './spanishCopy';
import englishCopy from './englishCopy';

interface ILanguageCopy {
  [key: string]: string;
}

export const getCopy = (language: string): ILanguageCopy => {
  if (language === 'spanish') {
    return spanishCopy;
  }
  return englishCopy;
};

export default getCopy;
