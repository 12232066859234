// Packages
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

// Hooks
import { useLocalStorage } from '../../hooks/useLocalStorage';

// Components
import { ReactComponent as SpectrumNewsLogo } from '../../svgs/ssa_spectrum_news_logo.svg';
import { ReactComponent as SportsNetLogo } from '../../svgs/sportsNetLogo.svg';

// Utils
import getCopy from '../../utils/languages/getCopy';
import {
  getBaseURL,
  getMVPDListURL,
  getRequestorId,
  isSpecNews,
  getValidRedirectURL
} from '../../utils/utilityFunctions/utilityFunctions';

// Styles
import './ChooseProvider.scss';

// Types
import { MVPD, thirdParties, MVPDList, MVPDLogos } from './ChooseProviderTypes';

const ChooseProvider = (props: any) => {
  // State
  const [MVPDLogosAndIds, setMVPDLogosAndIds] = useState<MVPDLogos>([]);
  const [logoImages, setLogoImages] = useState<React.ReactElement[]>([]);

  // Hooks
  const [language, setLanguage] = useLocalStorage('language', 'english');

  const getMVPDLogos = (thirdPartyMVPDList: MVPD[]): MVPDLogos => {
    const MVPDLogos: MVPDLogos = [];
    thirdPartyMVPDList.forEach((MVPD: MVPD) => {
      if (
        !MVPD?.platforms ||
        MVPD?.platforms?.includes(props?.history?.location?.state?.device)
      ) {
        MVPDLogos.push({
          id: MVPD?.mvpdid || '',
          logoUrl: MVPD?.logoUrl || '',
          displayName: MVPD?.displayName || '',
        });
      }
    });
    return MVPDLogos;
  };

  const fetchMVPDList = async () => {
    let thirdPartyMVPDList: thirdParties;
    const MVPDListResponse = await fetch(getMVPDListURL());
    const MVPDListJson: MVPDList = (await MVPDListResponse?.json()) || {};
    thirdPartyMVPDList = MVPDListJson?.thirdParties || [];
    setMVPDLogosAndIds(getMVPDLogos(thirdPartyMVPDList));
  };

  useEffect(() => {
    fetchMVPDList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const logoImageComponents = [];
    for (let i = 0; i < MVPDLogosAndIds.length; i++) {
      const { logoUrl, id, displayName } = MVPDLogosAndIds[i];
      const logoImageComponent = (
        <div
          key={i}
          className="chooseProvider-screen__mvpdLogoContainer"
          onClick={() => handleLogoClick(id)}
        >
          <img
            src={logoUrl}
            className="chooseProvider-screen__mvpdLogo"
            alt={displayName}
            onClick={() => handleLogoClick(id)}
            onError={handleImageError}
          />
        </div>
      );
      logoImageComponents.push(logoImageComponent);
    }
    setLogoImages(logoImageComponents);
  }, [MVPDLogosAndIds]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  const handleLanguageToggle = () => {
    const displayLanguage = language === 'english' ? 'spanish' : 'english';
    setLanguage(displayLanguage);
  };

  const handleImageError = (e: any) => {
    e.target.outerHTML = `<div style="font-size: 24px; font-weight: 500"}>${e.target.alt}</div>`;
  };

  const handleLogoClick = (mvpd: string) => {
    const regCode = props?.history?.location?.state?.regCode || '';
    const baseURL = props?.history?.location?.state?.baseURL || getBaseURL();
    const host = getValidRedirectURL();
    const url = `${baseURL}/api/v1/authenticate?mso_id=${mvpd}&requestor_id=${getRequestorId()}&reg_code=${regCode}&no_flash=true&no_iframe=true&domain_name=${host}&redirect_url=https%3A%2F%2F${host}%2Fsuccess`;
    window.location.href = url;
  };

  return (
    <div className="chooseProvider-screen">
      <div className="chooseProvider-screen__logo">
        {isSpecNews() ? <SpectrumNewsLogo /> : <SportsNetLogo />}
      </div>
      <h1 className="chooseProvider-screen__title">
        {getCopy(language).chooseProviderTitle}
      </h1>
      <p className="chooseProvider-screen__description">
        {getCopy(language).chooseProviderDescription}
      </p>
      {logoImages}
      <button
        className="chooseProvider-screen__language"
        onClick={handleLanguageToggle}
      >
        {language === 'english' ? `Español` : `English`}
      </button>
    </div>
  );
};

export default withRouter(ChooseProvider);
