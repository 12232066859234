// Packages
import React, { useEffect } from 'react';

// Hooks
import { useQuantum } from '../../hooks/useQuantum';
import { useLocalStorage } from '../../hooks/useLocalStorage';

// Components
import { ReactComponent as SpectrumNewsLogo } from '../../svgs/ssa_spectrum_news_logo.svg';
import { ReactComponent as SportsNetLogo } from '../../svgs/sportsNetLogo.svg';

// Utils
import getCopy from '../../utils/languages/getCopy';
import { isSpecNews } from '../../utils/utilityFunctions/utilityFunctions';

// Styles
import './Success.scss';

const Success = () => {
  // Hooks
  const [language, setLanguage] = useLocalStorage('language', 'english');

  useEffect(() => {
    //@ts-ignore
    if (!window.digitalData) {
      //@ts-ignore
      window.digitalData = { eventName: 'login success' };
    }
  }, []);

  // Quantum
  const { trackPageView, trackLoginStop } = useQuantum();
  useEffect(() => {
    trackPageView('Success');
    trackLoginStop(true);
  }, [trackPageView, trackLoginStop]);

  // Handlers
  const handleLanguageToggle = () => {
    const displayLanguage = language === 'english' ? 'spanish' : 'english';
    setLanguage(displayLanguage);
  };

  return (
    <div className="success-screen">
      <div className="success-screen__logo">
        {isSpecNews() ? (
          <SpectrumNewsLogo />
        ) : (
          <SportsNetLogo />
        )}
      </div>
      <h2 className="success-screen__title">
        {getCopy(language).successTitle}
      </h2>
      <p className="success-screen__description">
        {isSpecNews() ? getCopy(language).successDescription1SpecNews : getCopy(language).successDescription1SportsNet}
      </p>
      <p className="success-screen__description">
        {getCopy(language).successDescription2}
      </p>
      <button
        type="button"
        className="news-ssa__button--language"
        onClick={handleLanguageToggle}
      >
        {language === 'english' ? `Español` : `English`}
      </button>
    </div>
  );
};

export default Success;
