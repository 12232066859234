export const getBaseURL = () => {
  if (isProd()) {
    return 'https://api.auth.adobe.com';
  }
  return 'https://api.auth-staging.adobe.com';
};

export const getMVPDListURL = () => {
  if (isSpecNews()) {
    return isProdMVPD()
      ? 'https://spectrumnews1.com/content/dam/News/static/local/app_configs/tvapp_config_ssa_production.json'
      : 'https://spectrumnews1.com/content/dam/News/static/local/app_configs/tvapp_config_ssa_internal.json';
  }
  return isProdMVPD()
    ? 'https://spectrumnews1.com/content/dam/News/static/local/app_configs/tvapp_config_sportsnetssa_production.json'
    : 'https://spectrumnews1.com/content/dam/News/static/local/app_configs/tvapp_config_sportsnetssa_internal.json';
};

export const isSpecNews = (): boolean => {
  const ssaFlow = sessionStorage.getItem('ssaflow');
  if (ssaFlow === 'sportsnet') {
    return false;
  }
  if (ssaFlow === 'spectrumnews') {
    return true;
  }
  // default to Spectrum News
  return true;
};

export const isProd = (): boolean => {
  const env = sessionStorage.getItem('env');
  if (env === 'staging') {
    return false;
  }
  if (env === 'prod') {
    return true;
  }
  // default to prod
  return true;
};

export const isProdMVPD = (): boolean => {
  const mvpd = sessionStorage.getItem('mvpd');
  if (mvpd === 'staging') {
    return false;
  }
  if (mvpd === 'prod') {
    return true;
  }
  // default to prod
  return true;
};

export const getRequestorId = (): string => {
  return isSpecNews() ? 'SPECTRUMNEWS' : 'TWCSPORTSNET';
};

// When running app locally, use staging endpoint for redirect_url to avoid error
export const getValidRedirectURL = (): string => {
  let { host } = window.location;

  if (host === 'localhost:3000') {
    if (isSpecNews()) {
      return 'stage-news.news-ssa.spectrumtoolbox.com';
    }
    return 'stage-sports.news-ssa.spectrumtoolbox.com';
  }

  return host;
};
