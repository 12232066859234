// Packages
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';

// Hooks
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useQuantum } from '../../hooks/useQuantum';

// Components
import { ReactComponent as SpectrumNewsLogo } from '../../svgs/ssa_spectrum_news_logo.svg';
import { ReactComponent as SportsNetLogo } from '../../svgs/sportsNetLogo.svg';
import { KiteButton } from '@kite/react-kite';

// Utils
import getCopy from '../../utils/languages/getCopy';
import { getVerbatimErrors } from '../../utils/apiCalls/verbatimErrors';
import {
  getBaseURL,
  getRequestorId,
  isSpecNews,
} from '../../utils/utilityFunctions/utilityFunctions';

// Styles
import './RegCodeForm.scss';

type RegcodeResponse = {
  info: {
    deviceType?: string;
    deviceInfo?: string;
  };
};

function parseOsName(jsonStr: RegcodeResponse): string | null {
  return (
    decodeDeviceInfo(jsonStr?.info?.deviceInfo)?.operatingSystem?.name || null
  );
}

type DeviceInfo = {
  operatingSystem?: {
    name?: string;
  };
};
function decodeDeviceInfo(deviceInfo?: string): DeviceInfo | null {
  if (!deviceInfo) {
    return null;
  }

  try {
    return JSON.parse(atob(decodeURI(deviceInfo)));
  } catch (e) {
    console.error('Error decoding deviceInfo:');
    console.dir(e);
    return null;
  }
}

export interface IRegCodeFormProps {}

const RegCodeForm = (props: any) => {
  // State
  const [regCode, setRegCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [codeError, setCodeError] = useState('');
  const [apiStatus, setApiStatus] = useState(0);
  const [verbatimError, setVerbatimError] = useState('');

  // Hooks
  const [language, setLanguage] = useLocalStorage('language', 'english');
  const { trackSelectAction, analytics, trackLoginStart } = useQuantum();
  const checkForQueryParams = () => {
    let { search } = window.location;
    const qrCode = new URLSearchParams(search).get('code');
    if (qrCode) {
      setRegCode(qrCode.toUpperCase());
      handleSubmit(null, qrCode);
    }
  };

  useEffect(() => {
    checkForQueryParams();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Verbatim Errors
  const { data: verbatimErrors } = useQuery(['verbatimErrors'], () =>
    getVerbatimErrors()
  );

  useEffect(() => {
    let verbatimCode: string = '';
    switch (apiStatus) {
      // Add error code mapping
      case 404:
        verbatimCode = 'NSALI-1010';
        break;
      case 500:
        verbatimCode = 'NSALI-5000';
        break;
      default:
        verbatimCode = '';
    }
    const verbatimMessage = verbatimErrors
      ? verbatimErrors.errorCodes.find(
          (errorMessage: any) => errorMessage.full_error_code === verbatimCode
        )
      : null;
    let message = `${
      verbatimMessage?.full_customer_message || getCopy(language)[verbatimCode]
    } ${verbatimMessage?.full_error_code || verbatimCode}`;
    // Uses translated copy rather than English Verbatim message. Displays error code from Verbatim.
    if (language === 'spanish') {
      message = `${getCopy(language)[verbatimCode]} ${
        verbatimMessage?.full_error_code || verbatimCode
      }`;
    }
    verbatimCode ? setVerbatimError(message) : setVerbatimError('');
  }, [verbatimErrors, apiStatus, language]);

  const inputElement = useRef<any>(null);

  useEffect(() => {
    if (codeError) {
      inputElement?.current?.focus();
    }
  }, [codeError]);

  const fetchRegistration = async (code: string) => {
    let baseURL = getBaseURL();
    const adobePromise = await fetch(
      `${baseURL}/reggie/v1/${getRequestorId()}/regcode/${code}?format=json`
    );

    const reader = adobePromise.body?.getReader();
    let device: string | null = null;

    await reader?.read().then(function processText({ value }): any {
      const decodedString = new TextDecoder().decode(value);
      const decodedJson = JSON.parse(decodedString) as RegcodeResponse;
      device =
        decodedJson?.info?.deviceType || parseOsName(decodedJson) || null;
    });

    return {
      device,
      response: adobePromise,
      baseURL,
    };
  };

  const trackApiCall = (
    opSuccess: boolean,
    appApiResponseCode: string,
    code: string
  ) => {
    analytics?.track('apiCall', {
      opSuccess,
      appApiResponseCode,
      appApiResponseTimeMs: 0,
      appApiHost: getBaseURL(),
      appApiPath: `reggie/v1/${getRequestorId()}/regcode/${code}?format=json`,
      appApiHttpVerb: 'GET',
    });
  };

  // Handlers

  const handleSuccessfulRegistrationFetch = (
    response: any,
    localCode: string,
    baseURL: string,
    device: string | null
  ) => {
    trackApiCall(true, `${response.status}`, localCode);
    // const payload = { code: localCode, method: qrCode ? 'qr code' : 'manual' };
    //@ts-ignore
    // window._satellite.track('submit', payload);
    setIsLoading(false);
    setCodeError('');
    trackLoginStart('manualAuth');

    props.history.push({
      pathname: '/signin',
      state: {
        regCode: localCode,
        baseURL,
        device,
      },
    });
  };

  const handleUnsuccessfulRegistrationFetch = (
    response: any,
    localCode: string
  ) => {
    trackApiCall(false, `${response.status}`, localCode);
    //@ts-ignore
    // window._satellite.track('error');
    setApiStatus(response.status);
    setIsLoading(false);
    setCodeError('invalid');
  };

  const handleSubmit = async (e?: any, qrCode?: string) => {
    e?.preventDefault();

    const localCode = qrCode?.toUpperCase() || regCode;
    if (!localCode.length) {
      setCodeError('empty');
      setApiStatus(0);
      setIsLoading(false);
      return;
    }
    trackSelectAction('Submit');
    setIsLoading(true);

    let { device, response, baseURL } = await fetchRegistration(localCode);

    if (response.ok) {
      handleSuccessfulRegistrationFetch(response, localCode, baseURL, device);
      return;
    }

    handleUnsuccessfulRegistrationFetch(response, localCode);
  };

  const handleEntry = (e: any) => {
    setCodeError('');
    const { value } = e.target;
    // Prevents non-alphanumeric characters
    if (value.match(/[^a-zA-Z0-9]+/gi)) {
      return;
    }
    setRegCode(value.toUpperCase());
  };

  const handleLanguageToggle = () => {
    const displayLanguage = language === 'english' ? 'spanish' : 'english';
    setLanguage(displayLanguage);
  };

  // Display Methods
  const codeInput = () => {
    const longInputClass = classNames({
      overflow1: regCode.length > 10,
      overflow2: regCode.length > 15,
      overflow3: regCode.length > 22,
      overflow4: regCode.length > 27,
      'reg-code-form__input': true,
    });
    return (
      <input
        key={`code-input`}
        id="code-input"
        ref={inputElement}
        name={`code-input`}
        value={regCode || ''}
        onChange={(e) => handleEntry(e)}
        className={longInputClass}
        aria-describedby={codeError ? 'error-message' : ''}
      />
    );
  };

  const displayError = () => {
    let errorMessage = '';
    switch (codeError) {
      case 'empty':
        errorMessage = getCopy(language).errorMessageEmpty;
        break;
      case 'invalid':
        errorMessage = getCopy(language)['NSALI-1010'];
        break;
      default:
        errorMessage = ``;
    }
    const errorClass = classNames({
      'reg-code-form__error': errorMessage,
      'reg-code-form__error--hidden': !errorMessage,
    });
    return <p className={errorClass}>{verbatimError || errorMessage}</p>;
  };

  return (
    <div className="reg-code-form">
      <div className="reg-code-form__logo">
        {isSpecNews() ? <SpectrumNewsLogo /> : <SportsNetLogo />}
      </div>
      <span className="reg-code-form__title">
        {getCopy(language).regCodeTitle}
      </span>
      <span className="reg-code-form__description">
        {isSpecNews()
          ? getCopy(language).regCodeDescriptionSpecNews
          : getCopy(language).regCodeDescriptionSportsNet}
      </span>
      <form
        className="reg-code-form__form"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {codeInput()}
        <div className="reg-code-form__error--wrapper">{displayError()}</div>
        <KiteButton
          buttonType="submit"
          className="reg-code-form__button--submit"
          loading={isLoading}
        >
          {getCopy(language).regCodeSubmitButton}
        </KiteButton>
      </form>
      <button
        type="button"
        className="news-ssa__button--language"
        onClick={handleLanguageToggle}
      >
        {language === 'english' ? `Español` : `English`}
      </button>
    </div>
  );
};

export default withRouter(RegCodeForm);
